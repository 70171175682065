// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (max-width: 768px) {
  * {
    columns: 1fr;
    padding: auto;
    margin: auto;
    width: auto;
    height: -moz-fit-content;
    height: fit-content;
  }
  
  .sidebar {
    columns: 1fr;
  }

}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE;IACE,YAAY;IACZ,aAAa;IACb,YAAY;IACZ,WAAW;IACX,wBAAmB;IAAnB,mBAAmB;EACrB;;EAEA;IACE,YAAY;EACd;;AAEF","sourcesContent":["@media screen and (max-width: 768px) {\n  * {\n    columns: 1fr;\n    padding: auto;\n    margin: auto;\n    width: auto;\n    height: fit-content;\n  }\n  \n  .sidebar {\n    columns: 1fr;\n  }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
