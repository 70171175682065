import React from 'react'
import ExerciseApp from '../img/Exerise App Screenshot.png';
import './Pages.css'
import { Link } from 'react-router-dom';

const Exercise = () => {
  return (
    <div className='container'>
      <header className='title'>
        <h1>Get Fit</h1>
        <h3>A way to search for exercises and exercise videos!</h3>
      </header>
      <div className='screenshot'>
        <img src={ExerciseApp} alt='Screenshot of exercise search app' />
      </div>
      <div className='text'>
        <p className='project-description'>
          Made using react and RapidApi. Never used api's to get data before and this was my first 
          time doing so. Was also my first time installing different packages for different features.
          This was one of the first sites that I had built using the tutorial, which led me to
          currently making a Pokedex!
        </p>
        <div className='btn-box'>
          <Link to={'https://github.com/m-davis25/xercise'}>
            <button className='btn-link'>Source Code</button>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Exercise