import React, { useState,useRef } from 'react'
import emailjs from '@emailjs/browser';
import './Pages.css'

const Contact = () => {
  const form = useRef();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  
  function sendMail(e){
    const serviceID = "service_w9awr8y";
    const templateID = "template_4x6w79e";
    const publicKey = "5zkyRDZtkvppUViw7";
    e.preventDefault();
    emailjs.sendForm(serviceID, templateID, form.current, publicKey)
      .then((result) => {
          console.log(result.text);
          alert("Your mesage has sent sucessfully!")
      }, (error) => {
          console.log(error.text);
      });
  };


  return (
    <div className='container'>
      <header className='title'>
        <h1>Contact</h1>
      </header>
      <div className='contact-info'>
        <div className='contact-box'>
          <h3>Shoot me an Email!</h3>
          <form ref={form} onSubmit={sendMail} className='email-box'>
            <label>Name:</label>
            <br />
            <input 
              name='name'
              id='name'
              value={name}
              onChange={(event) =>setName(event.target.value)}
              type='text'
              placeholder='Enter Your Name'
              required 
            />
            <br />
            <label>Email:</label>
            <br />
            <input 
              name='email'
              id='email'
              value={email}
              onChange={(event) =>setEmail(event.target.value)}
              type='text'
              placeholder='Enter Your Email'
              required
            />
            <br />
            <label>Message:</label>
            <br />
            <textarea 
              name='message'
              id='message'
              value={message}
              onChange={(event) =>setMessage(event.target.value)}
              rows={3}
              className='message-box'
              placeholder='Enter Your Message Here'
              required
            >
            </textarea>
            <br />
            <button className='submit-button'>Submit</button>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Contact