import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from './Pages/Home';
import About from './Pages/About';
import Blog from './Pages/Blog';
import Book from './Pages/Book';
import Contact from './Pages/Contact';
import Exercise from './Pages/Exercise';
import Project from './Pages/Project';
import Layout from './Components/Layout';
import './App.css';


function App() {
  return (
    <Routes>
      <Route path='/' element={<Layout />}>
        <Route path='/home' element={<Home />} />
        <Route path='/about' element={<About />} />
        <Route path='/projects' element={<Project />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/blog' element={<Blog />} />
        <Route path='/book' element={<Book />} /> 
        <Route path='/exercise' element={<Exercise />} />
      </Route>
    </Routes>
  );
}

export default App;
