import React from 'react'
import Boston from '../img/Boston-Skyline.webp';
import './Pages.css'

const Home = () => {
  return (
    <div className='container'>
      <header className='title'>
        <h1>Hi! My name is Makeda</h1>
        <h3>Front End Web Developer</h3>
      </header>
      <div className='boston'>
        <img className='boston-skyline' src={Boston} alt='Boston Skyline' />
      </div>
    </div>
  )
}

export default Home