import React from 'react'
import BlogPic from '../img/Blog Screenshot.png';
import './Pages.css'
import { Link } from 'react-router-dom';

const Blog = () => {
  return (
    <div className='container'>
      <header className='title'>
        <h1>MyBlog Blogging App</h1>
        <h3>Got something you wanna say? Post about it here!</h3>
      </header>
      <div className='screenshot'>
        <img src={BlogPic} alt='Screenshot of blog' />
      </div>
      <div className='text'>
        <p className='project-description'>
          Blog was made using React, MongoDB, Express, and NodeJS. I had alot of fun making this one.
          First time learning how the backend connects to the front end. I learned how the internet connects to the server and how
          authentication works! The backend is connected to MongoDB, which has the database for usernames and blog posts.
          Once logged in, the user can edit blog posts. Once I expand my knowledge, I would like to add user profiles
          so you can see posts from your blogs only, plus add more animations, like what MySpace did in the mid 2000s.
        </p>
        <div className='btn-box'>
          <Link to={"https://github.com/m-davis25/Blog-App"}>
            <button className='btn-link'>Source Code</button>
          </Link>
        </div>
        
      </div>

    </div>
  )
}

export default Blog