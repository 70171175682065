import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import Logo from '../img/logo.png';
import Github from '../img/github.svg';
import Linkedin from '../img/linkedin.svg';
import './sidebar.css';


const Sidebar = () => {


    const [isNavOpen, setIsNavOpen] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            if(window.innerWidth < 600 && isNavOpen){
                setIsNavOpen(false)
            }
        }
        window.addEventListener("resize", handleResize)
        return() => window.removeEventListener("resize", handleResize)
    }, [isNavOpen])
    
    return (
        <>
            <button 
                onClick={() => setIsNavOpen(!isNavOpen)} 
                className='sidebar-toggle'
            >
                <span class="material-symbols-outlined">
                    {isNavOpen ? "menu": "menu"}
                </span>
                {/* <span class="material-symbols-outlined">
                {isNavOpen ? "toggle_on": "toggle_off"}
                </span> */}
            </button>
            <nav className={`nav ${isNavOpen ? "nav-open" : "nav-closed" }`}>
                <div className='logo'>
                    <img src={Logo} alt='My Web Logo'/>
                </div>
                <div className='look-sidebar'>
                    <h2>Look Around!</h2>
                </div>
                <div className='links'>
                    <ul>
                        <li>
                            <Link style={{textDecoration: 'none'}} to={'/home'}>Home</Link>
                        </li>
                        <li>
                            <Link style={{textDecoration: 'none'}} to={'/about'}>About</Link>
                        </li>
                        <li>
                            <Link style={{textDecoration: 'none'}} to={'/projects'}>Projects</Link>
                        </li>
                        <li>
                            <Link style={{textDecoration: 'none'}} to={'/contact'}>Contact</Link>
                        </li>
                    </ul>
                </div>
                <div className='social-media'>
                    <Link to={"https://linkedin.com/in/makeda-d-ab5534b3/"}>
                        <img className='icons' src={Linkedin} alt='linkedin' />
                    </Link>
                    <Link to={"https://github.com/m-davis25"}>
                        <img className='icons' src={Github} alt='github' />
                    </Link>
                </div>
            </nav>
        </>
    )
}
   
export default Sidebar