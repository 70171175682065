import React from 'react'
import LibraryPic from '../img/Book Recording App.png';
import './Pages.css'
import { Link } from 'react-router-dom';

const Book = () => {
  return (
    <div className='container'>
      <header className='title'>
        <h1>Library Logger</h1>
        <h3>Got something you wanna say? Post about it here!</h3>
      </header>
      <div className='screenshot'>
        <img src={LibraryPic} alt='Screenshot of book recording app' />
      </div>
      <div className='text'>
        <p className='project-description'>
          Made using MongoDB, ReactJS, Express and NodeJS. Just a book app I created to reinforce
          what I've learned coding. Took 2 hours to make. No limit on how many books you can add.
        </p>
        <div className='btn-box'>
          <Link to={'https://github.com/m-davis25/Book-Recording-App'}>
            <button className='btn-link'>Source Code</button>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Book