import React from 'react'
import Library from '../img/Library App.png';
import Exercise from '../img/Get Fit Exercise.png';
import Blog from '../img/MyBlog.png';
import './Pages.css'
import { Link } from 'react-router-dom';

const Project = () => {
 
  return (
    <div className='container'>
      <header className='title'>
        <h1>Projects</h1>
        <h3>Check out my projects here!</h3>
      </header>
      <div className='project-links'>
        <div className='project-box'>
          <Link to={'/blog'}><img src={Blog} alt='blog-pic' /></Link>
          <Link to={'/exercise'}><img src={Exercise} alt='exercise-pic' /></Link>
          <Link to={'/book'}><img src={Library} alt='library-pic' /></Link>
        </div>
      </div>
    </div>
  )
}

export default Project