import React from 'react'
import Sidebar from './Sidebar'
// import './sidebar.css';
import { Outlet } from 'react-router'

const Layout = () => {
  return (
    <main>
        <Sidebar />
        <Outlet />
    </main>
  )
}

export default Layout