import React from 'react'
import Selfie from '../img/IMG_1938.jpeg';
import Spiderman from '../img/Spiderman-Logo-1994.png';
import Pokemon from '../img/Pokemon-Logo.png';
import FinalFantasy from '../img/Final-Fantasy-Logo-768x432.png';
import Tekken from '../img/Tekken-Logo-1994-768x483.png';
import FireEmblem from '../img/Fire_Emblem.svg';
import './Pages.css'

const About = () => {
  return (
    <div className='container'>
      <header className='title'>
        <h1>About the Developer</h1>
      </header>
      <div className='pic'>
        <img className='selfie' src={Selfie} alt='self' />
      </div>
      <div className='web-bio'>
        <p className='dev-info'>
          Hello and welcome to my site. My name is Makeda. 
          I was born and raised in Boston, Massachusetts. 
          I started coding in July 2022 after realizing that I wanted a career change. 
          My main languages that I program in are HTML, CSS, and JavaScript. 
          My interest in web development excellerated when I got into my car accident in October 2022.
          Being on medical leave for 4 months, I had gotten bored and wanted something to do.
          I started learning little by litte and now I am able to code this 
          website you are on now!! When not coding, I like to attend anime and video game conventions,
          I go to Pax East every year!
        </p>
      </div>
      <div className='music-bio'>
        <h2>My Favorite Artists</h2>
        <p class="artists-bio">
          In my free time, I love listening to music and playing video games. My favorite artists are: Beyonce, Megan thee Stallion, BLACKPINK, TWICE, and Red Velvet.
        </p>
      </div>
      <div className='vg-bio'>
        <h2>My Favorite Video Games</h2>
        <div className='logo'>
          <div className='column'>
            <img src={Pokemon} alt='pokemon' />
          </div>
          <div className='column'>
            <img src={FireEmblem} alt='fire emblem' />
          </div>
          <div className='column'>
            <img src={FinalFantasy} alt='final fantasy' />
          </div>
          <div className='column'>
            <img src={Tekken} alt='tekken' />
          </div>
          <div className='column'>
            <img src={Spiderman} alt='spiderman' />
          </div>
        </div>
      </div>
    </div>
  )
}

export default About